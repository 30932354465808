import {
  StudentGradesCohortMap,
  StudentGradesFilters,
  StudentGradesPageCohort,
  StudentGradesPageEngagement,
  StudentGradesPageStudent,
  StudentGradesSortType,
} from "@contexts/StudentGradesDataProvider/types";
import { SelectMenuOption } from "@shared";
import {
  SubjectComboWithAll,
  WeekdayData,
} from "@shared/AttendanceGrades/types";
import { getLongestCohortNameWidth } from "@shared/AttendanceGrades/utils";
import { conditionalReverse } from "@utils/array";
import {
  getIsoDayEndTzTime,
  getIsoDayStartTzTime,
  normalizeDateFromUTCDateTime,
} from "@utils/dateTime";
import { sortAlphanumerics, sortGradeLevels } from "@utils/sort";
import { assertUnreachable } from "@utils/types";
import { TW_3XL_BREAKPOINT } from "contexts/LayoutProvider/constants";
import { StudentGradesSort } from "contexts/StudentGradesDataProvider";
import { cloneDeep } from "lodash";
import {
  ATTENDANCE_COL_MIN_WIDTH,
  GRADES_COL_MIN_WIDTH,
  STUDENT_COL_MIN_WIDTH,
  STUDENT_GRADE_COL_MIN_WIDTH,
} from "./constants";

export const getColumnWidths = (
  screenWidth: number,
  cohortsColWidth: number
): number[] =>
  screenWidth > TW_3XL_BREAKPOINT
    ? [
        Math.min(Math.round(100 + screenWidth * 0.03), 230),
        STUDENT_GRADE_COL_MIN_WIDTH + 35,
        Math.max(Math.round(100 + screenWidth * 0.03), cohortsColWidth),
        ATTENDANCE_COL_MIN_WIDTH + 15,
        GRADES_COL_MIN_WIDTH + 25,
      ]
    : [
        STUDENT_COL_MIN_WIDTH,
        STUDENT_GRADE_COL_MIN_WIDTH,
        cohortsColWidth,
        ATTENDANCE_COL_MIN_WIDTH,
        GRADES_COL_MIN_WIDTH,
      ];

export const getCohortColWidth = (cohortsMap: StudentGradesCohortMap): number =>
  getLongestCohortNameWidth(Object.values(cohortsMap).map((c) => c.name));

// Filters out Cohorts with zero relevant (SubjectCombo) Events
// Also filters non-relevant Events from CohortEvents & returns Map
export const getCohortMap = (
  cohorts: StudentGradesPageCohort[],
  subjectCombo: SubjectComboWithAll,
  cohortIdFilter: string | null
) => {
  const cohortsMap: StudentGradesCohortMap = new Map();
  const relevantCohorts: StudentGradesPageCohort[] = [];

  cohorts.forEach((c) => {
    const cohort = cloneDeep(c);
    cohort.cohortEvents = c.cohortEvents.filter(
      ({ cohortSubject, cohortSubSubject }) =>
        subjectCombo.subject === cohortSubject &&
        (!subjectCombo.subSubject ||
          subjectCombo.subSubject === cohortSubSubject)
    );
    if (cohort.cohortEvents.length > 0) relevantCohorts.push(c);
  });

  relevantCohorts
    .filter(({ id }) => !cohortIdFilter || id === cohortIdFilter)
    .forEach((cohort) => {
      if (cohort.cohortEvents.length > 0) cohortsMap.set(cohort.id, cohort);
    });

  return {
    cohortsMap,
    cohortOptions: [
      { id: "", value: "All Cohorts" },
      ...relevantCohorts
        .map(({ name, id }) => ({
          id,
          value: name,
        }))
        .sort((a, b) => sortAlphanumerics(a.value, b.value)),
    ] as SelectMenuOption[],
  };
};

export const sortStudentsByStudentDetails = async (
  activeStudents: StudentGradesPageStudent[],
  cohortsMap: StudentGradesCohortMap,
  sort: StudentGradesSort
): Promise<StudentGradesPageStudent[]> => {
  const { sortType, isSortedDesc } = sort;

  switch (sortType) {
    case StudentGradesSortType.Student:
      return conditionalReverse(
        activeStudents.sort((a, b) =>
          (a.studentName ?? "").localeCompare(b.studentName ?? "")
        ),
        isSortedDesc
      );
    case StudentGradesSortType.StudentGrade:
      return conditionalReverse(
        activeStudents.sort((a, b) =>
          sortGradeLevels(a.studentGrade, b.studentGrade)
        ),
        isSortedDesc
      );
    case StudentGradesSortType.Cohorts:
      return conditionalReverse(
        activeStudents.sort((a, b) => {
          const aCohortNames = getSortedRelevantCohortNames(a, cohortsMap);
          const bCohortNames = getSortedRelevantCohortNames(b, cohortsMap);
          return sortAlphanumerics(aCohortNames, bCohortNames);
        }),
        isSortedDesc
      );
    case StudentGradesSortType.School:
      return conditionalReverse(
        activeStudents.sort((a, b) =>
          (a.schoolName ?? "").localeCompare(b.schoolName ?? "")
        ),
        isSortedDesc
      );
    case StudentGradesSortType.ClassroomTeacher:
      return conditionalReverse(
        activeStudents.sort((a, b) =>
          (a.classroomTeacherName ?? "").localeCompare(
            b.classroomTeacherName ?? ""
          )
        ),
        isSortedDesc
      );
    case StudentGradesSortType.Grades:
    case StudentGradesSortType.Attendance:
      return activeStudents;
    default:
      assertUnreachable(sortType, "sort.key");
  }
};

export const getSortedRelevantCohortNames = (
  { studentCohortStudents }: StudentGradesPageStudent,
  cohortsMap: StudentGradesCohortMap
): string =>
  studentCohortStudents
    .filter(({ cohortId }) => cohortsMap.has(cohortId))
    .map(({ cohortName }) => cohortName)
    .sort((a, b) => sortAlphanumerics(a, b))
    .join(", ");

export const getFilteredStudents = (
  students: StudentGradesPageStudent[],
  cohortsMap: StudentGradesCohortMap,
  weekdaysData: WeekdayData[],
  studentGradesFilters: StudentGradesFilters,
  { timeZone, startDate }: StudentGradesPageEngagement
): StudentGradesPageStudent[] => {
  const engStart = normalizeDateFromUTCDateTime(new Date(startDate));
  const weekEndTime = getIsoDayEndTzTime(weekdaysData[6].isoDate, timeZone);
  const weekStartTime = getIsoDayStartTzTime(weekdaysData[0].isoDate, timeZone);
  const viewIsPreEngStart = weekStartTime < engStart.getTime();

  const {
    cohortIdFilter,
    schoolNameFilter,
    teacherNameFilter,
    studentGradeFilter,
    showRemovedStudentsFilter,
  } = studentGradesFilters;

  return students.filter(
    ({
      schoolName,
      studentGrade,
      classroomTeacherName,
      studentCohortStudents,
      engagementStartDate: studentStartAt,
      engagementRemovalDate: studentRemovedAt,
    }) => {
      const notInGradeFilter =
        !!studentGradeFilter && studentGradeFilter !== studentGrade;
      if (notInGradeFilter) return false;

      const notInTeacherFilter =
        !!teacherNameFilter && teacherNameFilter !== classroomTeacherName;
      if (notInTeacherFilter) return false;

      const notInSchoolFilter =
        !!schoolNameFilter && schoolNameFilter !== schoolName;
      if (notInSchoolFilter) return false;

      const notInRelevantCohorts =
        studentCohortStudents.filter((cs) => {
          if (!cohortsMap.has(cs.cohortId)) return false;
          if (!cohortIdFilter) return true;

          return (
            !cs.cohortRemovalDate ||
            new Date(cs.cohortRemovalDate).getTime() > weekStartTime
          );
        }).length === 0;

      if (notInRelevantCohorts) return false;

      const studentNeverRemoved = !studentRemovedAt;
      const studentHasNotYetStarted = studentStartAt > weekEndTime;

      if (showRemovedStudentsFilter) return true;
      if (viewIsPreEngStart) return true;
      if (studentHasNotYetStarted) return false;
      return studentNeverRemoved || studentRemovedAt > weekStartTime;
    }
  );
};

export const getSideMenuPadding = (isVisitorRoute: boolean) =>
  (isVisitorRoute ? 0 : 240) + 16 * 5;
