import {
  Cohort,
  EngagementStudentGrades_PageDataFragment,
  GradeLevel,
  Student,
  StudentGradesTable_StudentGradesPeriodAveragesFragment,
  StudentGradesTable_StudentGradesRecordFragment,
} from "@generated/graphql";
import { ISODate } from "@utils/dateTime";

export enum StudentGradesSortType {
  School = "School",
  Grades = "Grades",
  Student = "Student",
  Cohorts = "Cohorts",
  Attendance = "Attendance",
  StudentGrade = "Student Grades",
  ClassroomTeacher = "Classroom Teacher",
}

export type StudentGradesSort = {
  isSortedDesc: boolean;
  sortType: StudentGradesSortType;
};

export type StudentGradesFilters = {
  endDateFilter: Date | null;
  startDateFilter: Date | null;
  cohortIdFilter: string | null;
  schoolNameFilter: string | null;
  teacherNameFilter: string | null;
  showRemovedStudentsFilter: boolean;
  studentGradeFilter: GradeLevel | null;
};

export type StudentGradesPageData = EngagementStudentGrades_PageDataFragment;
export type StudentGradesPageCohort = StudentGradesPageData["cohorts"][0];
export type StudentGradesPageStudent = StudentGradesPageData["students"][0];
export type StudentGradesPageEngagement = StudentGradesPageData["engagement"];

export type StudentGradesCohortMap = Map<Cohort["id"], StudentGradesPageCohort>;

export type StudentDayDataMap = Map<
  ISODate,
  StudentGradesTable_StudentGradesRecordFragment[]
>;

export type StudentGradesWeekDataMap = Map<Student["id"], StudentDayDataMap>;

export type StudentGradesAveragesDataMap = Map<
  Student["id"],
  StudentGradesTable_StudentGradesPeriodAveragesFragment
>;

export type GradesColWidthsMap = Record<StudentGradesSortType, number>;
